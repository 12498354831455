import { SetStateAction, Dispatch, useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Column from "./Column";
import { Switch, Typography, Input, Select, MenuItem } from "@mui/material";
import formatExpiration from "../../../utils/format_expiration";
import createNewFormattedExpiration from "../../../utils/create_new_formatted_expiration";
import dayjs from "dayjs";

interface Pass {
  active_duration: number;
  begins_on: Date;
  duration_units: string;
  expires_on: Date;
  max_allowed_users: number;
  order_id: number;
  pass_type: string;
  product_id: number;
  purchased_on: Date;
  session_id_actived_on: string;
  _id: string;
}

export default function AddTime({
  addTime,
  setAddTime,
  selectedPassData,
  duration,
  setDuration,
  durationUnits,
  setDurationUnits,
}: {
  addTime: boolean;
  setAddTime: Dispatch<SetStateAction<boolean>>
  selectedPassData: Pass;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  durationUnits: string;
  setDurationUnits: Dispatch<SetStateAction<string>>;
}) {
  
  useEffect(() => {
    // It's an anti pattern to update a value that this effect
    // has as a dependency.
    // What is a better way to update the default time
    // as we toggle adding time on and off?
    if (addTime === false) {
      setDuration(0);
    }
    if (addTime === true && duration === 0) {
      setDuration(1);
    }
  }, [addTime, duration]);

  const currentExpiration = useMemo(() => {
    let currentExpirationDisplay = "?";
    if (selectedPassData?.expires_on) {
      currentExpirationDisplay = formatExpiration(selectedPassData.expires_on);
    }

    return (
      <Grid
        xs={11}
        md={3}
        sx={{
          height: "100%",
        }}
      >
        <Column header={<Typography>Current Expiration</Typography>}>
          {currentExpirationDisplay}
        </Column>
      </Grid>
    );
  }, [selectedPassData]);

  const additionalTime = useMemo(() => {
    return (
      <Grid
        xs={11}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column
          header={<Typography>{addTime ? "Additional Time" : ""}</Typography>}
        >
          {addTime && (
            <Grid container justifyContent="center">
              <Grid
                // xs={6}
                sx={{
                  paddingRight: "6px",
                }}
              >
                <Input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(Number(e.target.value))}
                  required
                  color="primary"
                  inputProps={{
                    min: 0, // Must be 0 for auto duration_units switching to work
                  }}
                  sx={{
                    width: "60px",
                    height: "100%",
                  }}
                />
              </Grid>
              <Grid
              // xs={6}
              >
                <Select
                  type="number"
                  value={durationUnits}
                  onChange={(e) => setDurationUnits(e.target.value)}
                  label="Choose Pass Type"
                  required
                  color="primary"
                >
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
              </Grid>
            </Grid>
          )}
        </Column>
      </Grid>
    );
  }, [addTime, selectedPassData, duration, durationUnits]);

  const newExpiration = useMemo(() => {
    let offset = 0;
    if (selectedPassData?.expires_on) {
      offset = dayjs(selectedPassData.expires_on).diff();
    }
    let newExpirationDisplay = createNewFormattedExpiration(
      {
        active_duration: duration,
        duration_units: durationUnits,
      },
      offset
    );

    return (
      <Grid
        xs={12}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column
          header={<Typography>{addTime ? "New Expiration" : ""}</Typography>}
          last
        >
          {addTime && newExpirationDisplay}
        </Column>
      </Grid>
    );
  }, [addTime, selectedPassData, duration, durationUnits]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="start"
      sx={{
        height: "100%",
      }}
    >
      <Grid
        xs={1}
        sx={{
          height: "100%",
        }}
      >
        <Column header={<Typography>Add Time?</Typography>} first>
          <Switch
            checked={addTime}
            onChange={(e) => setAddTime(e.target.checked)}
          />
        </Column>
      </Grid>

      {currentExpiration}

      {additionalTime}

      {newExpiration}
    </Grid>
  );
}
