import { useEffect, useState } from "react";
import Debug from "debug";
const debug = Debug("SS:Passes:ModifyAccess");
import { createStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Typography, Button, Link } from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";
import calculatePassPrice from "../../../utils/calculatePassPrice";
import getPassData from "../Utils/getPassData";
import useMaxViewers from "../Hooks/use_max_viewers";
// Date and Time
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import CurrentPass from "../Elements/CurrentPass";
import AddViewers from "../Elements/AddViewers";
import AddTime from "../Elements/AddTime";
import Column from "../Elements/Column";

dayjs.extend(utc);
dayjs.extend(duration);

interface Props {
  // uuid: props.uuid;
  // inProgress: inProgress;
  // setInProgress: setInProgress;
  // cssClasses: props.cssClasses;
  // changeStep: changeStep;
  // handleClose: handleClose;
  // displayData: displayData;
  // actionData: actionData;
  // modalAction: props.modalAction;
  // availablePasses: Pass[];
  // activePasses: Pass[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    messages: {
      marginTop: theme.spacing(12),
      borderTop: "solid 1px " + theme.palette.background.light,
      padding: theme.spacing(4),
    },
    greenBorder: {
      border: "2px solid " + theme.palette.primary.main,
    },
    redBorder: {
      border: "2px solid " + theme.palette.warning.main,
    },
    strikethrough: {
      textDecoration: "line-through",
      opacity: 0.4,
    },
    subTotal: {
      opacity: 0.8,
    },
    discounts: {
      opacity: 0.5,
    },
    total: {
      fontWeight: "bold",
    },
  })
);

export default function ModifyAccess(props: Props) {
  const classes = useStyles();
  const [allowCheckout, setAllowCheckout] = useState(false);

  // Choices
  const [addTime, setAddTime] = useState<boolean>(false);
  const [addViewers, setAddViewers] = useState<boolean>(false);

  // Upgrade Pass Data
  const [newViewers, setNewViewers] = useState(0);
  const [duration, setDuration] = useState(0);
  const [durationUnits, setDurationUnits] = useState("hours");

  // Current Pass
  const [selectedPass, setSelectedPass] = useState("");
  const [selectedPassData, setSelectedPassData] = useState();

  // Display
  const maxViewers = useMaxViewers({
    activeSubs: [],
    activePasses: props.activePasses,
  });
  const [isPartner, setIsPartner] = useState(false);
  const [partnerDiscount, setPartnerDiscount] = useState(0);
  const [proRateDiscount, setProRateDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [monthlyWarning, setMonthlyWarning] = useState(false);

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Automatically select the first active pass
  useEffect(() => {
    if (
      props.activePasses.length > 0 &&
      props.activePasses[0] &&
      props.activePasses[0].expires_on
    ) {
      setSelectedPass(props.activePasses[0]._id);
    }
  }, [props.activePasses]);

  // Change pass selection
  useEffect(() => {
    if (!selectedPass) return;
    const passData = getPassData(props.activePasses, selectedPass);
    debug("Selected Pass", passData, selectedPass);
    if (!passData) return;

    setSelectedPassData(passData);

    // Update defaults
    if (passData.duration_units) {
      setDurationUnits(passData.duration_units);
    }
  }, [selectedPass]);

  // Update partner discount
  useEffect(() => {
    if (durationUnits === "months") {
      setIsPartner(false);
    } else {
      setIsPartner(props.isPartner);
    }
  }, [props.isPartner, durationUnits]);

  // Set Add Time Defaults
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units
    ) {
      let active_duration = selectedPassData.active_duration;

      // Update minutes to hours - Backwards compatability
      if (selectedPassData.duration_units === "minutes") {
        active_duration = selectedPassData.active_duration / 60;
      }

      // Set Duration
      // if (props.scheduledPasses.length > 0) {
      //   let nextScheduled = props.scheduledPasses.reduce((p, c) =>
      //     dayjs(c.begins_on).isBefore(p.begins_on) ? c : p
      //   );

      //   // Don't overlap scheduled passes
      //   setDuration(
      //     // Don't overlap scheduled passes
      //     dayjs(selectedPassData.expires_on).isAfter(
      //       dayjs(nextScheduled.begins_on)
      //     ) && props.addTime
      //       ? dayjs(nextScheduled.begins_on).diff(
      //           dayjs(selectedPassData.expires_on),
      //           "hour"
      //         )
      //       : props.addTime
      //       ? active_duration
      //       : 0
      //   );
      // } else {
      // Default to the same duration as selected pass
      setDuration(addTime ? active_duration : 0);
      // }

      // Convert Minutes to default as Hours
      if (selectedPassData.duration_units === "minutes") {
        setDurationUnits("hours");
      } else {
        setDurationUnits(selectedPassData.duration_units);
      }
    }
  }, [selectedPassData]);

  // Auto switch pass type
  useEffect(() => {
    // Only update durations is adding time
    if (addTime) {
      // Switch from 7 hours to 1 day automatically at 8 hours
      if (durationUnits === "hours" && duration >= 8) {
        setDurationUnits("days");
        setDuration(1);
        // Reset monthly warning
        if (monthlyWarning) {
          setMonthlyWarning(null);
        }
      }
      // Switch from 1 day to 7 hours automatically at 0 days
      if (durationUnits === "days" && duration < 1) {
        setDurationUnits("hours");
        setDuration(7);
        // Reset monthly warning
        if (monthlyWarning) {
          setMonthlyWarning(null);
        }
      }
      // Don't allow 0 hours
      if (durationUnits === "hours" && duration === 0) {
        setDuration(1);
      }
      // Notify user of monthly passes
      if (durationUnits === "days" && duration > 5) {
        setMonthlyWarning(true);
      } else {
        setMonthlyWarning(false);
      }

      // Allow checkout
      if (duration > 0) {
        setAllowCheckout(true);
      } else {
        setAllowCheckout(false);
      }
    }
  }, [duration, durationUnits]);

  // Update expiration and cost displays
  useEffect(() => {
    // Only process if pass data exists
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units &&
      selectedPassData.expires_on
    ) {
      // Cost
      let newPrice = 0;
      let proRateAmount = 0;

      // Adding Viewers
      if (newViewers > 0) {
        let newViewersCost = calculatePassPrice(
          maxViewers + newViewers,
          selectedPassData.active_duration,
          selectedPassData.duration_units
        );
        let existingViewersCost = calculatePassPrice(
          selectedPassData.max_allowed_users,
          selectedPassData.active_duration,
          selectedPassData.duration_units
        );
        let addedViewersCost = Number(newViewersCost - existingViewersCost);

        // Pro Rate viewer upgrades
        let durationLeft = dayjs(selectedPassData.expires_on).diff(
          dayjs(),
          "hour"
        );
        let totalDuration = dayjs
          .duration(
            selectedPassData.active_duration,
            selectedPassData.duration_units
          )
          .asHours();
        let proRateDiscount = durationLeft / totalDuration;

        // Update price and pro rate if applicable
        newPrice = addedViewersCost;

        if (proRateDiscount > 0 && proRateDiscount <= 1) {
          proRateAmount = newPrice - newPrice * proRateDiscount;
          setProRateDiscount(proRateAmount);
          newPrice = newPrice * proRateDiscount;
        }
      } else {
        setProRateDiscount(0);
      }

      // Adding Time
      if (duration > 0) {
        newPrice += calculatePassPrice(
          maxViewers + newViewers,
          duration,
          durationUnits
        );
      }

      if (!Number.isNaN(newPrice)) {
        // Add partner discount
        let partnerPrice = Number(newPrice);
        if (isPartner) {
          setPartnerDiscount(Number(newPrice) * 0.2);
          partnerPrice = partnerPrice * 0.8;
        }

        // Store Prices
        setSubTotal(newPrice + proRateAmount);
        setTotal(partnerPrice);

        // Confirmation Data
        let newDisplayData = {};
        newDisplayData[selectedPass] = {
          currentPass: selectedPassData.pass_type,
          newViewers: maxViewers + newViewers,
          additionalTime:
            duration +
            " " +
            durationUnits.toUpperCase() +
            durationUnits.slice(1),
          price: "$" + partnerPrice.toFixed(2),
        };
        setDisplayData(newDisplayData);
        setActionData({
          url: "/api/modify_access/",
          postData: {
            pass_to_update: selectedPass,
            additional_time: duration,
            additional_time_units: durationUnits,
            additional_viewers: newViewers,
            price: partnerPrice,
          },
          actionText: "adding viewers and time",
        });

        // Allow checkout
        if (newViewers > 0 || duration > 0) {
          setAllowCheckout(true);
        } else {
          setAllowCheckout(false);
        }
      }
    }
  }, [newViewers, duration, durationUnits, selectedPassData, isPartner]);

  return (
    <Grid 
      container 
      spacing={6} 
      justifyContent="end"
      sx={{ 
        height: "100%",
        width: "100%",
        margin: 0
      }}
    >
      {/* Current Pass */}
      <Grid xs={12} sx={{ minHeight: "112px" }}>
        <CurrentPass
          activePasses={props.activePasses}
          selectedPass={selectedPass}
          setSelectedPass={setSelectedPass}
        />
      </Grid>

      {/* Totals */}
      <Grid xs={12} sx={{ minHeight: "112px" }}>
        <AddViewers
          addViewers={addViewers}
          setAddViewers={setAddViewers}
          selectedPassData={selectedPassData}
          newViewers={newViewers}
          setNewViewers={setNewViewers}
        />
      </Grid>

      <Grid xs={12} sx={{ minHeight: "112px" }}>
        <AddTime
          addTime={addTime}
          setAddTime={setAddTime}
          selectedPassData={selectedPassData}
          duration={duration}
          setDuration={setDuration}
          durationUnits={durationUnits}
          setDurationUnits={setDurationUnits}
        />
      </Grid>

      <Grid>
        <Column header={<Typography>Total</Typography>} first last>
          {/* Totals Data */}
          <Grid
            container
            sx={{
              backgroundColor: "background.paper",
              marginBottom: 8,
            }}
            padding={6}
            alignItems="center"
          >
            {/* Checkout */}
            <Grid xs={6} paddingRight={6}>
              <Typography className={classes.subTotal}>
                SubTotal: ${subTotal.toFixed(2)}
              </Typography>
              {proRateDiscount > 0 && (
                <Typography className={classes.discounts}>
                  ProRate: (- ${proRateDiscount.toFixed(2)})
                </Typography>
              )}
              {isPartner && (
                <Typography className={classes.discounts}>
                  Partner: (- ${partnerDiscount.toFixed(2)})
                </Typography>
              )}
              <Typography className={classes.total}>
                Total: ${total.toFixed(2)}
              </Typography>
            </Grid>

            <Grid xs={6} paddingLeft={6} paddingRight={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  props.changeStep("Confirm", displayData, actionData, false)
                }
                disabled={!allowCheckout}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Column>
      </Grid>

      {/* Error message */}
      <Grid xs={12}>
        <Grid container className={classes.messages}>
          {monthlyWarning && (
            <Grid item xs={12} className={props.cssClasses.tableCell}>
              <Typography>
                A monthly subscription may save you money.
              </Typography>
              <Typography>
                {"Would you like to "}
                <Link
                  onClick={() =>
                    props.changeStep("Purchase Subscription", {}, {}, false)
                  }
                  underline="hover"
                >
                  Purchase a Subscription
                </Link>
                {" instead?"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
