import { useEffect, useMemo, useState } from "react";
import Debug from "debug";
const debug = Debug("SS:Passes:Dispaly");
import { Grid, Typography, Button, Tooltip } from "@mui/material/";
import DisplayAccessDetails from "./DisplayAccessDetails";
import formatExpiration from "../../../utils/format_expiration";

export default function DisplayActivePasses(props) {
  // State
  const [passViewers, setPassViewers] = useState(0);
  const [subViewers, setSubViewers] = useState(0);
  const [accountViewers, setAccountViewers] = useState(0);
  const [accountAvailableViewers, setAccountAvailableViewers] = useState(0);
  const [onlineViewers, setOnlineViewers] = useState(0);
  const [onlineAccountViewers, setOnlineAccountViewers] = useState(0);

  // Effects
  // Update Access totals
  useEffect(() => {
    let passUserCount = 0;
    let subUserCount = 0;
    let accountUserCount = 0;

    // Passes
    if (props.activePasses && props.activePasses.length > 0) {
      props.activePasses.forEach((pass, i) => {
        passUserCount += pass.max_allowed_users;
      });
    }
    // Subscriptions
    if (props.activeSubs && props.activeSubs.length > 0) {
      props.activeSubs.forEach((sub, i) => {
        if (sub.session_id_attached_to === "account") {
          accountUserCount += sub.max_allowed_users;
        } else {
          subUserCount += sub.max_allowed_users;
        }
      });
    }

    setPassViewers(passUserCount);
    setSubViewers(subUserCount);
    setAccountViewers(accountUserCount);
    debug("Online Viewers", passUserCount, subUserCount, accountUserCount);
  }, [props.activePasses, props.activeSubs]);

  // Update online viewer count
  useEffect(() => {
    if (props.presenceRoom) {
      const handleMemberUpdate = () => {
        let memberCount = 0;
        props.presenceRoom.members?.each((member) => {
          if (
            member.info?.viewerType === "email" ||
            member.info?.viewerType === "web"
          ) {
            memberCount++;
          }
        });
        debug(
          "New Online Viewer Count",
          memberCount,
          props.presenceRoom.members
        );
        setOnlineViewers(memberCount);
      };

      if (props.presenceRoom) {
        // This will set the initial online viewer count
        props.presenceRoom.bind(
          "pusher:subscription_succeeded",
          handleMemberUpdate
        );

        // These will update the viewer count over time
        props.presenceRoom.bind("pusher:member_added", handleMemberUpdate);
        props.presenceRoom.bind("pusher:member_removed", handleMemberUpdate);

        return () => {
          props.presenceRoom?.unbind(
            "pusher:subscription_succeeded",
            handleMemberUpdate
          );
          props.presenceRoom?.unbind("pusher:member_added", handleMemberUpdate);
          props.presenceRoom?.unbind(
            "pusher:member_removed",
            handleMemberUpdate
          );
        };
      }
    }
  }, [props.presenceRoom, props.channel]);

  // Update account total viewers
  useEffect(() => {
    let accountAvailableUsers = 0;

    if (props.availableSubs && props.availableSubs.length > 0) {
      props.availableSubs.forEach((sub) => {
        if (sub.session_id_attached_to === "account") {
          accountAvailableUsers += sub.max_allowed_users;
        }
      });
    }
    debug("Account Available Viewers", accountAvailableUsers);
    setAccountAvailableViewers(accountAvailableUsers);
  }, [props.availableSubs]);

  // Update onlineAccountViewers
  useEffect(() => {
    const accountViewersOnline = onlineViewers - passViewers - subViewers;
    debug("Account Online Viewers", accountViewersOnline);
    if (accountViewersOnline > 0) {
      setOnlineAccountViewers(accountViewersOnline);
    } else {
      setOnlineAccountViewers(0);
    }
  }, [onlineViewers, passViewers, subViewers]);

  const renderer = useMemo(() => {
    if (props.activeSubs?.length > 0 || props.activePasses?.length > 0) {
      // Active pass
      return (
        <Grid container>
          <Grid xs={6}>
            <Tooltip
              title={
                <DisplayAccessDetails
                  activePasses={props.activePasses}
                  activeSubs={props.activeSubs}
                  availableSubs={props.availableSubs}
                  accountViewers={accountViewers}
                  accountAvailableViewers={accountAvailableViewers}
                  onlineViewers={onlineViewers}
                  onlineAccountViewers={onlineAccountViewers}
                  cssClasses={props.cssClasses}
                />
              }
              placement="bottom"
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "background.light",
                    border: "1px solid",
                    borderColor: "secondary.main",
                    borderRadius: 2,
                  },
                },
              }}
            >
              <Typography variant="h6" className={props.cssClasses.alertText}>
                {passViewers +
                  subViewers +
                  accountViewers +
                  onlineAccountViewers}
                {" Viewers ("}
                <Typography display="inline" color="primary">
                  {onlineViewers} online
                </Typography>
                )
              </Typography>
            </Tooltip>
          </Grid>

          <Grid xs={6}>
            {!props.isBroadcastOnly && (
              <Button
                onClick={() => props.handleOpen("activate")}
                variant="contained"
                color="secondary"
              >
                MANAGE ACCESS
              </Button>
            )}
          </Grid>
        </Grid>
      );
    } else {
      // No active pass
      return (
        <Grid container>
          <Grid xs={6}>
            <Typography
              variant="subtitle1"
              className={props.cssClasses.alertText}
            >
              NO ACTIVE PASS
            </Typography>
          </Grid>

          <Grid xs={6}>
            {!props.isBroadcastOnly && (
              <Button
                onClick={() => props.handleOpen("activate")}
                variant="contained"
                color="primary"
              >
                ACTIVATE PASS
              </Button>
            )}
          </Grid>
        </Grid>
      );
    }
  }, [
    props,
    onlineAccountViewers,
    onlineViewers,
    accountViewers,
    passViewers,
    subViewers,
  ]);

  return (
    <Grid container>
      <Grid xs={12}>{renderer}</Grid>
    </Grid>
  );
}
