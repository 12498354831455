import { Dispatch, SetStateAction, useMemo } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Select, MenuItem, Typography } from "@mui/material";
import formatExpiration from "../../../utils/format_expiration";
import Column from "./Column";

interface Pass {
  active_duration: number;
  begins_on: Date;
  duration_units: string;
  expires_on: Date;
  max_allowed_users: number;
  order_id: number;
  pass_type: string;
  product_id: number;
  purchased_on: Date;
  session_id_actived_on: string;
  _id: string;
}

export default function CurrentPass({
  activePasses,
  selectedPass,
  setSelectedPass
}: {
  activePasses: Pass[];
  selectedPass: string;
  setSelectedPass: Dispatch<SetStateAction<string>>
}) {
  const currentExpirationDisplay = useMemo(() => {
    if (selectedPass) {
      const pass = activePasses.find((ps) => ps._id === selectedPass);
      if (pass) {
        return formatExpiration(pass.expires_on);
      }
    }
    return "?";
  }, [activePasses, selectedPass]);

  const currentViewerCount = useMemo(() => {
    if (selectedPass) {
      const pass = activePasses.find((ps) => ps._id === selectedPass);
      if (pass) {
        return pass.max_allowed_users;
      }
    }
    return "?";
  }, [activePasses, selectedPass]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="start"
      sx={{
        height: "100%",
      }}
    >
      
      <Grid
        xs={11}
        md={3}
        sx={{
          height: "100%",
        }}
      >
        <Column 
          header={<Typography>Pass To Activate</Typography>} 
          first={true}
        >
          <Select 
            value={selectedPass}
            onChange={e => setSelectedPass(e.target.value)}
          >
            {activePasses.map((pass) => (
              <MenuItem value={pass._id}>{pass.pass_type}</MenuItem>
            ))}
          </Select>
        </Column>
      </Grid>

      <Grid xs={1} sx={{ height: "100%" }}>
        <Column header=""><Grid sx={{height: "100%"}}></Grid></Column>
      </Grid>

      <Grid
        xs={6}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column header={<Typography>Current Viewers</Typography>}>
          {currentViewerCount}
        </Column>
      </Grid>

      <Grid
        xs={6}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column header={<Typography>Current Expiration</Typography>} last>
          {currentExpirationDisplay}
        </Column>
      </Grid>

    </Grid>
  );
}
