import { ReactNode, useMemo } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { SxProps } from "@mui/material";

export default function Column({
  header,
  children,
  first = false,
  last = false,
}: {
  header: ReactNode;
  children: ReactNode;
  first?: Boolean;
  last?: Boolean;
}) {
  const renderer = useMemo(() => {
    let containerSx: SxProps = {
      backgroundColor: "background.paper",
      height: "100%",
    }
    let headerSx: SxProps = {
      backgroundColor: "secondary.main",
      height: "32px",
    };
    let dataSx: SxProps = {
      margin: "auto",
      flex: 1,
      width: "100%",
    };

    // Adjust the ends
    if (first) {
      headerSx.borderTopLeftRadius = 4;
      dataSx.borderBottomLeftRadius = 4
      containerSx.borderBottomLeftRadius = 4
    }
    if (last) {
      headerSx.borderTopRightRadius = 4;
      dataSx.borderBottomRightRadius = 4;
      containerSx.borderBottomRightRadius = 4;
    }

    return (
      <Grid
        container
        direction="column"
        sx={containerSx}
      >
        <Grid padding={4} sx={headerSx} alignContent="center">
          {header}
        </Grid>

        <Grid padding={4} sx={dataSx} alignContent="center">
          {children}
        </Grid>
      </Grid>
    );
  }, [header, children, first, last]);

  return renderer;
}
