import { useEffect, useState } from "react";
import { createStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography } from "@mui/material/";
import SelectPass from "../Elements/select_pass";
import getPassData from "../Utils/getPassData";
import createNewFormattedExpiration from "../../../utils/create_new_formatted_expiration";
import Column from "../Elements/Column";

export default function ActivatePass(props) {
  // Passes
  const [selectedPass, setSelectedPass] = useState("");
  const [selectedPassData, setSelectedPassData] = useState();

  // Display
  const [newExpirationDisplay, setNewExpirationDisplay] = useState("-");
  const [maxViewers, setMaxViewers] = useState("-");

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Update selected pass
  useEffect(() => {
    if (props.availablePasses && props.availablePasses.length > 0) {
      setSelectedPass(props.availablePasses[0]._id);
    }
  }, [props.availablePasses]);

  // Update selected pass data
  useEffect(() => {
    setSelectedPassData(getPassData(props.availablePasses, selectedPass));
  }, [selectedPass]);

  // Update selected pass display info
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units &&
      selectedPassData.pass_type &&
      selectedPassData.max_allowed_users
    ) {
      let newExpirationDisplay = createNewFormattedExpiration(
        selectedPassData,
        0
      );
      setNewExpirationDisplay(newExpirationDisplay);
      setMaxViewers(selectedPassData.max_allowed_users);

      let newDisplayData = {};
      newDisplayData[selectedPass] = {
        passType: selectedPassData.pass_type,
        newExpiration: newExpirationDisplay,
        maxViewers: selectedPassData.max_allowed_users,
      };
      setDisplayData(newDisplayData);
      setActionData({
        url: "/api/access_passes/",
        postData: {
          pass_id: selectedPassData._id,
          to_schedule: false,
        },
        actionText: "activating a pass",
      });
    }
  }, [selectedPassData]);

  return (
    <Grid 
      container 
      spacing={6} 
      sx={{ 
        height: "100%",
        width: "100%",
        margin: 0
      }}
    >
      <Grid xs={12}>
        <Grid container>
          <Grid xs={12} md={4}>
            <Column header={<Typography>Pass To Activate</Typography>} first>
              <SelectPass
                passes={props.availablePasses}
                cssClasses={props.cssClasses}
                selectedPass={selectedPass}
                setSelectedPass={setSelectedPass}
              />
            </Column>
          </Grid>

          <Grid xs={4} md={3}>
            <Column header={<Typography>Will Expired</Typography>}>
              <Typography>{newExpirationDisplay}</Typography>
            </Column>
          </Grid>

          <Grid xs={4} md={2}>
            <Column header={<Typography>Viewers</Typography>}>
              <Typography>{maxViewers}</Typography>
            </Column>
          </Grid>

          <Grid xs={4} md={3}>
             <Column header={<Typography>Activate</Typography>} last>
              <Button
                onClick={() =>
                  props.changeStep("Confirm", displayData, actionData, false)
                }
                variant="contained"
                color="primary"
              >
                Activate
              </Button>
            </Column>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
