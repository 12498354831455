import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

export default function AccessDetailsTable({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "background.paper",
        marginBottom: 8,
        borderBottom: "1px solid",
        borderColor: "secondary.light",
        borderRadius: 2,
      }}
    >
      <Grid
        xs={12}
        padding={2}
        sx={{
          width: "100%",
          backgroundColor: "secondary.main",
          borderRadius: 2,
          paddingLeft: 4,
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid xs={12} padding={4}>
        {children}
      </Grid>
    </Grid>
  );
}
