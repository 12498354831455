import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material/';

// Steps
import SelectOption from '../Steps/select_option_step';
import ActivatePass from '../Steps/activate_pass_step';
import AttachSubscription from '../Steps/attach_subscription_step';
import SchedulePass from '../Steps/schedule_pass_step';
import ManageScheduled from '../Steps/manage_scheduled_passes_step';
import ModifyAccess from '../Steps/ModifyAccessStep';
import PurchasePass from '../Steps/purchase_pass_step';
import ConfirmationStep from '../Steps/confirmation_step';
import CompletedStep from '../Steps/completed_step';
import SubscriptionSelect from '../../Subscriptions/subscription_select';

export default function ManageAccessStepperDialog(props) {
  const [inProgress, setInProgress] = useState(false);
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([
      'What would you like to do?',
    ]);

  useEffect(() => {
    handleClose();
  }, [props.closePassDialog]);

  // Handle next step selection
  const changeStep = (step, displayData, actionData, toReset) => {
    let newSteps = steps;
    if (toReset) {
      newSteps = [
        'What would you like to do?',
        step
      ]
    } else {
      newSteps.push(step);
    }
    setSteps(newSteps);
    setCurrentStep(steps.length - 1);
    setDisplayData(displayData);
    setActionData(actionData);
  }

  // Reset to the begining
  const resetCurrentStep = () => {
    setSteps([
      'What would you like to do?',
    ]);
    setCurrentStep(0);
    setDisplayData({});
    setActionData({});
  }

  // Close the modal
  const handleClose = () => {
    resetCurrentStep();
    props.handleClose();
  }

  // Return the tab to render
  const tabRender = (step) => {
    let propsData = {
      ...props,
      uuid:props.uuid,
      inProgress:inProgress,
      setInProgress:setInProgress,
      cssClasses:props.cssClasses,
      changeStep:changeStep,
      handleClose:handleClose,
      displayData:displayData,
      actionData:actionData,
      modalAction:props.modalAction,
      availablePasses:props.availablePasses,
      activePasses:props.activePasses
    }

    switch (steps[currentStep]) {
      case 'What would you like to do?':
        return <SelectOption
          {...propsData}
        />
      case 'Purchase Pass':
        return <PurchasePass
          {...propsData}
        />;
      case 'Purchase Subscription':
        return <SubscriptionSelect
          {...propsData}
        />
      case 'Attach Subscription':
        return <AttachSubscription
          {...propsData}
        />
      case 'Activate Pass':
        return <ActivatePass
          {...propsData}
        />
      case 'Schedule Pass':
        return <SchedulePass
          {...propsData}
        />
      case 'Manage Scheduled':
        return <ManageScheduled
          {...propsData}
        />;
      case 'Add Viewers / Time':
        return <ModifyAccess
          {...propsData}
        />
      case 'Confirm':
        return <ConfirmationStep
          {...propsData}
        />
      case 'Complete':
      case 'Error':
        return <CompletedStep
          {...propsData}
        />
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
    >

      <DialogTitle
        className={props.cssClasses.dtitle}
      >
        { props.modalAction === 'activate' ? 'Manage Access' : 'Manage Schedule'}
      </DialogTitle>

      <DialogContent
        className={props.cssClasses.dcontent}
      >
        <Stepper
          activeStep={currentStep}
          orientation="vertical"
        >
          {
            steps.map((step, index) => (
              <Step
                active={currentStep === index}
                key={step}
              >
                <StepLabel>
                  {step}
                </StepLabel>

                <StepContent>
                  {
                    currentStep === index &&
                    tabRender(step[index])
                  }
                </StepContent>
              </Step>
            ))
          }
        </Stepper>

      </DialogContent>

      <DialogActions
        className={props.cssClasses.dactions}
      >
        {
          currentStep != 0 &&
          steps[currentStep] != 'Complete' &&
          <Button
            id='reset'
            onClick={resetCurrentStep}
            variant="contained"
            color="secondary"
          >
              Reset
          </Button>
        }

        <Button
          id = 'Close'
          onClick = {handleClose}
          variant="contained"
        >
          {
            steps[currentStep] === 'Complete' ?
            'Close' : 'Cancel'
          }
        </Button>
      </DialogActions>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>

    </Dialog>
  );

}
