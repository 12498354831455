import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Column from "./Column";

interface SubData {
  count: number;
  max_allowed_users: number;
}

export default function AppliedSubscriptions({
  appliedSub
} : {
  appliedSub: SubData
}) {

  return (
    <Grid container>
      <Grid xs={12} md={4}>
        <Column header={<Typography>Applied Subscriptions</Typography>} first>
          {appliedSub.count}
        </Column>
      </Grid>

      <Grid xs={4} md={3}>
        <Column header={<Typography>Applied From</Typography>}>
          <Typography>Account</Typography>
        </Column>
      </Grid>

      <Grid xs={4} md={2}>
        <Column header={<Typography>Viewers</Typography>}>
          {appliedSub.max_allowed_users}
        </Column>
      </Grid>

      <Grid xs={4} md={3}>
        <Column header={<Typography></Typography>} last>{""}</Column>
      </Grid>
    </Grid>
  );
}
