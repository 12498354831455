import { useEffect, useState } from "react";
import Debug from "debug";
const debug = Debug("SS:Subscription:AttachToProject");
import {
  // Grid,
  Typography,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";
import formatExpiration from "../../../utils/format_expiration";
import getPassData from "../Utils/getPassData";
import createNewFormattedExpiration from "../../../utils/create_new_formatted_expiration";
import Column from "../Elements/Column";
import AppliedSubscriptions from "../Elements/AppliedSubscriptions";
import AvailableSubscriptions from "../Elements/AvailableSubscriptions";

export default function AttachSubscription(props) {
  // Subs
  const [selectedSub, setSelectedSub] = useState("");
  const [selectedSubData, setSelectedSubData] = useState();
  const [selectedSubProjectName, setSelectedSubProjectName] = useState("");

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  const [availableSubs, setAvailableSubs] = useState([]);
  const [appliedSub, setAppliedSub] = useState([]);

  // Effects
  useEffect(() => {
    if (props.availableSubs?.length > 0) {
      setAvailableSubs(
        props.availableSubs.filter(
          (sub) =>
            !sub.session_id_attached_to
        )
      );

      setAppliedSub(
        props.availableSubs
          .filter(
            (sub) =>
              sub.session_id_attached_to &&
              sub.session_id_attached_to === "account"
          )
          .reduce(
            (p, c) => {
              if (c.max_allowed_users) {
                return {
                  max_allowed_users: p.max_allowed_users + c.max_allowed_users,
                  count: p.count + 1,
                };
              } else {
                return p;
              }
            },
            { max_allowed_users: 0, count: 0 }
          )
      );
    }
  }, [props.availableSubs]);

  // Update selected Sub
  useEffect(() => {
    if (availableSubs?.length > 0) {
      setSelectedSub(availableSubs[0]._id);
    }
  }, [availableSubs]);

  // Update selected pass data
  useEffect(() => {
    let subData = getPassData(availableSubs, selectedSub);
    setSelectedSubData(subData);

    // Set attached project name
    if (subData?.session_id_attached_to) {
      let newSelectedSubProjectName = props.subsProjectNames.find(
        (sub) => sub._id === subData.session_id_attached_to
      );

      setSelectedSubProjectName(newSelectedSubProjectName?.project_name);
    } else {
      setSelectedSubProjectName("");
    }
  }, [availableSubs, selectedSub]);

  // Update selected pass display info
  useEffect(() => {
    if (
      selectedSubData &&
      selectedSubData.active_duration &&
      selectedSubData.duration_units &&
      selectedSubData.max_allowed_users
    ) {
      // let newExpirationDisplay = createNewFormattedExpiration(
      //   selectedSubData,
      //   0
      // );
      // setNewExpirationDisplay(newExpirationDisplay);
      // setMaxViewers(selectedSubData.max_allowed_users);

      let newDisplayData = {};
      newDisplayData[selectedSub] = {
        Subscription: selectedSubData?.max_allowed_users + " Viewers",
        maxViewers: selectedSubData.max_allowed_users,
        expiresOn: formatExpiration(selectedSubData?.expires_on),
        currentlyAttachedTo: selectedSubProjectName,
      };
      setDisplayData(newDisplayData);
      setActionData({
        url: "/api/producer_subs/attach/",
        postData: {
          subscription_id: selectedSub,
          session_id: props.projectId,
        },
        actionText: "Attach To This Project",
      });
    }
  }, [selectedSubData]);

  return (
    <Grid 
      container 
      spacing={6} 
      sx={{ 
        height: "100%",
        width: "100%",
        margin: 0
      }}
    >
      <Grid xs={12}>
        {/* Available Subscriptions */}
        <AvailableSubscriptions
          availableSubs={availableSubs}
          selectedSub={selectedSub}
          setSelectedSub={setSelectedSub}
          selectedSubData={selectedSubData}
          selectedSubProjectName={selectedSubProjectName}
          displayData={displayData}
          actionData={actionData}
          changeStep={props.changeStep}
        />
      </Grid>

      <Grid xs={12}>
        {/* Applied Subscriptions */}
        <AppliedSubscriptions appliedSub={appliedSub} />
      </Grid>

      <Grid xs={12}>
        <Column header={<Typography></Typography>} first last>
          <Typography>
            Subscriptions that are attached to another project will not show up
            here.
          </Typography>
          <Typography>
            They must be removed or reassigned from the account dashboard.
          </Typography>
        </Column>
      </Grid>
    </Grid>
  );
}
