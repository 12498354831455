import formatExpiration from './format_expiration';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export default function createNewFormattedExpiration(pass, offset) {
  const allowedDurationUnits = [
    'minutes',
    'hours',
    'days',
    'weeks',
    'months',
    'years',
  ]

  let baseDate = new Date(Date.now() + offset);
  let expires;

  const format = (expiration) => {
    return formatExpiration(expiration)
  }

  switch (pass.duration_units) {
    case 'minutes':
      expires = new Date(baseDate).setMinutes(baseDate.getMinutes() + Number(pass.active_duration));
      return format(expires);
      break;
    case 'hours':
    default: // Assume hours if not supplied
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(pass.active_duration));
      return format(expires);
      break;
    case 'days':
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(Number(pass.active_duration) * 24));
      return format(expires);
      break;
    case 'weeks':
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(Number(pass.active_duration) * 24 * 7));
      return format(expires);
      break;
    case 'months':
      expires = new Date(baseDate).setMonth(baseDate.getMonth() + Number(pass.active_duration));
      return format(expires);
      break;
    case 'years':
      expires = new Date(baseDate).setFullYear(baseDate.getFullYear() + Number(pass.active_duration));
      return format(expires);
      break;

  }
}
