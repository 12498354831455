import {
  SetStateAction,
  Dispatch,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import Debug from "debug";
const debug = Debug("SS:Passes:ModifyAccess:AddViewers");
import Grid from "@mui/material/Unstable_Grid2";
import Column from "./Column";
import { Switch, Typography, Input } from "@mui/material";

interface Pass {
  active_duration: number;
  begins_on: Date;
  duration_units: string;
  expires_on: Date;
  max_allowed_users: number;
  order_id: number;
  pass_type: string;
  product_id: number;
  purchased_on: Date;
  session_id_actived_on: string;
  _id: string;
}

export default function AddViewers({
  addViewers,
  setAddViewers,
  selectedPassData,
  newViewers,
  setNewViewers,
}: {
  addViewers: boolean;
  setAddViewers: Dispatch<SetStateAction<boolean>>;
  selectedPassData: Pass;
  newViewers: number;
  setNewViewers: Dispatch<SetStateAction<number>>;
}) {

  useEffect(() => {
    if (addViewers === false) {
      setNewViewers(0);
    }
    if (addViewers === true && newViewers === 0) {
      setNewViewers(1);
    }
  }, [addViewers]);

  const currentViewerCount = useMemo(() => {
    let viewerCount = 0;
    if (selectedPassData && selectedPassData.max_allowed_users) {
      viewerCount = selectedPassData.max_allowed_users;
    }
    return (
      <Grid
        xs={11}
        md={3}
        sx={{
          height: "100%",
        }}
      >
        <Column 
          header={<Typography>Current Viewers</Typography>}
        >
          {viewerCount}
        </Column>
      </Grid>
    );
  }, [selectedPassData]);

  const additionalViewers = useMemo(() => {
    return (
      <Grid
        xs={6}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column
          header={
            <Typography>{addViewers ? "Additional Viewers" : ""}</Typography>
          }
        >
          {addViewers && (
            <Input
              type="number"
              value={newViewers}
              onChange={(e) => setNewViewers(Number(e.target.value))}
              required
              color="primary"
              inputProps={{
                min: 0, // Must be 0 for auto duration_units switching to work
              }}
              sx={{
                width: "60px",
                height: "100%",
              }}
            />
          )}
        </Column>
      </Grid>
    );
  }, [addViewers, newViewers]);

  const newViewerCount = useMemo(() => {
    let viewerCount = 0;
    if (selectedPassData && selectedPassData.max_allowed_users) {
      viewerCount = selectedPassData.max_allowed_users;
    }

    return (
      <Grid
        xs={6}
        md={4}
        sx={{
          height: "100%",
        }}
      >
        <Column
          header={<Typography>{addViewers ? "New Viewers" : ""}</Typography>}
          last
        >
          { addViewers && (viewerCount + newViewers)}
        </Column>
      </Grid>
    );
  }, [addViewers, selectedPassData, newViewers]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="start"
      sx={{
        height: "100%",
      }}
    >
      <Grid
        xs={1}
        sx={{
          height: "100%",
        }}
      >
        <Column 
          header={<Typography>Add Viewers?</Typography>}
          first
        >
          <Switch
            checked={addViewers}
            onChange={(e) => setAddViewers(e.target.checked)}
          />
        </Column>
      </Grid>

      {currentViewerCount}

      {additionalViewers}

      {newViewerCount}
    </Grid>
  );
}
