import { useMemo } from "react";
import Debug from "debug";
const debug = Debug("SS:Passes:DisplayAccessDetails");
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import formatExpiration from "../../../utils/format_expiration";
import AccessDetailsTable from "./AccessDetailsTable";

export default function DisplayAccessDetails(props) {
  const account = useMemo(() => {
    return (
      <AccessDetailsTable title="Account">
        <Typography variant="subtitle2">
          {props.accountViewers} Available / {props.accountAvailableViewers} Total
        </Typography>
      </AccessDetailsTable>
    );
  }, [props.accountViewers, props.accountAvailableViewers]);

  const passes = useMemo(() => {
    if (props.activePasses?.length > 0) {
      return (
        <AccessDetailsTable title="Passes">
          {props.activePasses.map((pass) => (
            <Grid container>
              <Grid
                sx={{
                  flex: 1,
                  paddingRight: 12,
                }}
              >
                <Typography variant="subtitle2">
                  {pass.max_allowed_users + " Viewers"}
                </Typography>
              </Grid>
              <Grid alignContent="flex-end">
                {"Until " + formatExpiration(pass.expires_on)}
              </Grid>
            </Grid>
          ))}
        </AccessDetailsTable>
      );
    } else {
      return (
        <AccessDetailsTable title="Passes">
          <Typography>-</Typography>
        </AccessDetailsTable>
      );
    }
  }, [props.activePasses]);

  const subs = useMemo(() => {
    if (props.activeSubs?.length > 0) {
      return (
        <AccessDetailsTable title="Subscriptions">
          {props.activeSubs
            ?.filter((sub) => sub.session_id_attached_to !== "account")
            ?.map((sub) => (
              <Grid container>
                <Grid
                  sx={{
                    flex: 1,
                    paddingRight: 12,
                  }}
                >
                  <Typography variant="subtitle2">
                    {sub.max_allowed_users + " Viewers"}
                  </Typography>
                </Grid>
                <Grid alignContent="flex-end">
                  {"Renews " + formatExpiration(sub.expires_on)}
                </Grid>
              </Grid>
            ))}
        </AccessDetailsTable>
      );
    } else {
      return (
        <AccessDetailsTable title="Subscriptions">
          <Typography>-</Typography>
        </AccessDetailsTable>
      );
    }
  }, [props.activeSubs]);

  return (
    <Grid container direction="column" padding={8} spacing={8}>
      <Grid xs={12}>
        <Typography variant="h6" align="center">
          Current Access
        </Typography>
      </Grid>

      <Grid xs={12}>{account}</Grid>

      <Grid xs={12}>{passes}</Grid>

      <Grid xs={12}>{subs}</Grid>
    </Grid>
  );
}
