import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Column from "./Column";
import { Dispatch, SetStateAction, useMemo } from "react";
import formatExpiration from "../../../utils/format_expiration";

interface Sub {
  _id: string;
  max_allowed_users: number;
  expires_on: Date;
}

interface DisplayData {
  Subscription: string;
  maxViewers: number;
  expiresOn: Date;
  currentlyAttachedTo: string;
};

interface ActionData {
  url: string;
  postData: {
    subscription_id: Sub;
    session_id: string;
  };
  actionText: string;
};

export default function AvailableSubscriptions({
  availableSubs,
  selectedSub,
  setSelectedSub,
  selectedSubData,
  displayData,
  actionData,
  changeStep
}: {
  availableSubs: Sub[];
  selectedSub: string;
  setSelectedSub: Dispatch<SetStateAction<string>>;
  selectedSubData: Sub;
  displayData: DisplayData
  actionData: ActionData
  changeStep: (step: string, displayData: DisplayData, actionData: ActionData, toReset: boolean) => void
}) {

  const expirationDisplay = useMemo(() => {
    if (selectedSubData?.expires_on) {
      return formatExpiration(selectedSubData.expires_on)
    }
    return "-"
  }, [selectedSubData])

  return (
    <Grid container sx={{ height: "100%" }}>
      {/* Available Subscriptions */}
      <Grid xs={12} md={4} sx={{ height: "100%" }}>
        <Column header={<Typography>Available Subscriptions</Typography>} first>
          <FormControl
            focused
            fullWidth
          >
            <InputLabel
              id="AvailableSubs"
            >
              Select a Subscription
            </InputLabel>
            <Select
              id="AvailableSubs"
              label="Select a Subscription"
              placeholder="Select a Subscription"
              variant="outlined"
              color="primary"
              fullWidth
              value={selectedSub === "" ? "default" : selectedSub}
              onChange={(e) => setSelectedSub(e.target.value)}
            >
              {availableSubs.map((sub, i) => (
                <MenuItem value={sub._id} key={"AP" + i}>
                  - {sub.max_allowed_users} Viewers -
                </MenuItem>
              ))}
              {availableSubs?.length === 0 && (
                <MenuItem value={"default"} disabled>
                  No subscriptions available. Try using the Account Dashboard
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Column>
      </Grid>

      <Grid xs={6} md={3} sx={{ height: "100%" }}>
        <Column header={<Typography>Viewers</Typography>}>
          {selectedSubData?.max_allowed_users}
        </Column>
      </Grid>

      <Grid xs={6} md={3} sx={{ height: "100%" }}>
        <Column header={<Typography>Expires</Typography>}>
          {expirationDisplay}
        </Column>
      </Grid>

      <Grid xs={12} md={2} sx={{ height: "100%" }}>
        <Column header={<Typography></Typography>}>
          <Button
            onClick={() =>
              changeStep("Confirm", displayData, actionData, false)
            }
          >
            Attach To Project
          </Button>
        </Column>
      </Grid>
    </Grid>
  );
}
